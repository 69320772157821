@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");
#root {
  height: 100%
}

@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

.footer-contact {
  font-size: 14px;
  color: #333;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-contact a {
  color: #007bff;
  text-decoration: none;
}

.footer-contact a:hover {
  text-decoration: underline;
}

.tree-container {
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

.warenTree2 {
  max-width: 100%;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word; /* для более надежного переноса слов */
}

.rct-node {
  white-space: normal; /* гарантируем, что текст будет переноситься */
  word-break: break-word; /* для переноса слов */
}


body {
  font-family: 'Raleway', sans-serif;
  background-color: #f5f7fa;
  color: #333;
  margin: 0;
  padding: 0;
}

header {
  background-color: #528bc4;
  padding: 20px;
  text-align: center;
  color: white;
}

header img {
  max-width: 200px;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.hero {
  background-color: #bbdbf3;
  text-align: center;
  padding: 50px 20px;
  border-radius: 0 0 20px 20px;
}

.hero h1 {
  font-size: 2.5em;
  margin: 0 0 10px 0;
}

.hero p {
  font-size: 1.2em;
}

.section {
  padding: 40px 20px;
}

.section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.section p {
  font-size: 1.2em;
  text-align: center;
}

.apps a img {
  width: 200px;
  margin: 20px 10px;
}

.carousel {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.carousel img {
  width: 200px;
  margin: 0 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.list-group {
  list-style: none;
  padding: 0;
}

.list-group-item {
  background: #fff;
  margin: 10px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.list-group-item h4 {
  margin: 0;
}

footer {
  background-color: #528bc4;
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 20px 20px 0 0;
}

footer p {
  margin: 5px 0;
}

footer a {
  color: white;
  text-decoration: none;
}

.social {
  display: inline-block;
  margin: 0 10px;
}

.social img {
  width: 30px;
}


.container {
  position: relative
}

.center-welcome-div {
  margin: auto;
  max-width: 900px
}

.profile {
  margin: 20px 0
}

.profile-sidebar {
  border: 3px solid #528bc4;
  border-radius: 20px;
  padding: 20px 0 10px;
  background: #fff;
  box-shadow: 0 10px 10px 10px #9ab8cd
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 50%;
  height: 50%;
  border-radius: 50%!important
}

.profile-usertitle {
  text-align: center;
  margin-top: 20px
}

.profile-usertitle-name {
  color: #5a7391;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px
}

.profile-usertitle-job {
  word-wrap: break-word;
  text-transform: uppercase;
  color: #5b9bd1;
  font-size: 11px;
  font-weight: 600;
  margin: auto auto 15px
}

.profile-userbuttons {
  text-align: center;
  margin-top: 10px
}

.profile-userbuttons .btn {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 15px;
  margin-right: 5px
}

.profile-userbuttons .btn:last-child {
  margin-right: 0
}

.profile-usermenu {
  margin-top: 30px;
}

.profile-usermenu ul li {
  border-bottom: 1px solid #f0f4f7;
}

.profile-usermenu ul li:last-child {
  border-bottom: none;
}

.profile-usermenu ul li a {
  color: #93a3b5;
  font-size: 14px;
  font-weight: 400;
}

.profile-usermenu ul li a i {
  margin-right: 8px;
  font-size: 14px;
}

.profile-usermenu ul li a:hover {
  background-color: #fafcfd;
    /*color: rgb(59, 89, 152);*/

}

.globalFont {
  font-family: "Montserrat",sans-serif
}

.profile-content {
  color: #528bc4;
  border: 3px #528bc4 solid;
  border-radius: 20px;
  padding: 20px;
  background: #fff;
  box-shadow: #9ab8cd 0px 10px 10px 10px;
  min-height: 460px;
}

.user-login {
  height: 100%;
  width: 100%
}

.user-login .block {
  display: flex
}

@media (max-width: 768px) {
  .user-login .block {
      flex-wrap:wrap
  }
}

@media (max-width: 768px) {
  .user-login .block .image {
      flex-grow:1;
      text-align: center;
      margin-bottom: 30px;
      margin-right: 0
  }
}

.user-login .block .image img {
  border-radius: 8px
}

.user-login .block .content {
  flex-basis: 90%;
  align-self: center;
  padding: 50px;
  background: #fff;
  border: 1px solid #cbccde;
  border-radius: 4px
}

@media (max-width: 768px) {
  .user-login .block .content {
      flex-grow:1
  }
}

.user-login .block .content .logo {
  margin-bottom: 80px
}

@media (max-width: 992px) {
  .user-login .block .content .logo {
      margin-bottom:40px
  }
}

.user-login .block .content .title-text {
  margin-bottom: 35px;
  text-align: center;
  color: #565eb1
}

.user-login .block .content .title-text h3 {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc
}

.user-login .block .content .new-acount {
  margin-top: 20px
}

.user-login .block .content .new-acount a,.user-login .block .content .new-acount p {
  font-size: 14px
}

.user-login .block .content .new-acount p a {
  color: #000
}

.tree,.tree ul {
  margin: 0;
  padding: 0;
  list-style: none
}

.tree ul {
  margin-left: 1em;
  position: relative
}

.tree ul ul {
  margin-left: .5em
}

.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid
}

.tree li {
  margin: 0;
  padding: 0 1em;
  line-height: 2em;
  color: #369;
  font-weight: 700;
  position: relative
}

.tree ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0
}

.tree ul li:last-child:before {
  background: #fff;
  height: auto;
  top: 1em;
  bottom: 0
}

.indicator {
  margin-right: 5px
}

.tree li a {
  text-decoration: none;
  color: #369
}

.tree li button,.tree li button:active,.tree li button:focus {
  text-decoration: none;
  color: #369;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  outline: 0
}

#landing-header {
  width: 100%;
  color: #528bc4
}

#landing-header a {
  color: #7c7c7e
}

#landing-header a:hover {
  color: #ffeb68
}

#landing-description {
  background: hsla(0,0%,98.8%,.863)
}

#landing-footer {
  color: #82b2e1;
  margin: 20px auto auto;
  width: 80%
}

#landing-footer,.apps,.col-lg-13 {
  text-align: center
}

.apps {
  margin: 20px auto auto;

}

.appscreenc {
  margin: 25px auto auto;
  border: 3px solid #fff;
  border-radius: 20px;
  min-width: 300px;
  width: 40%;
  background: #decb5a
}

.sliderimg {
  width: 55%;
  height: 30%;
  object-fit: cover
}

.column {
  word-wrap: break-word;
  color: #369;
  text-align: center;
  margin: 20px auto auto;
  width: 80%;
  border-top: 3px solid whitesmoke;
  border-left: 3px solid whitesmoke;
  border-right: 3px solid whitesmoke;
  border-bottom: 3px solid whitesmoke;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.6);
}

.container {
  text-align: center;
  margin-top: 25px;
  border-left: 3px solid transparent;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 10px
}

#main-div-block {
  background: #bbdbf3
}

.well {
  color: #000;
  background: #edd855;
  border: 1px solid transparent;
  border-radius: 5px 5px 0 0;
  margin-bottom: 0
}

.warn {
  margin-top: 0;
  background: #ee8793;
  color: #7d0606;
  border-bottom: 1px solid transparent;
  border-radius: 0 0 5px 5px
}

.payment-form {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  background-color: #f9f9f9;
}

@media (max-width: 768px) {
  .payment-form {
    max-width: 90%; /* Ширина формы на мобильных устройствах */
    padding: 15px;
  }

  button {
    font-size: 16px; /* Увеличение размера шрифта для кнопок */
  }
}


#payment-element {
  margin-bottom: 20px;
}

.btn-main-md {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}


.col-lg-3 {
  margin-top: 0;
  margin-bottom: 0
}

.policy {
  color: grey
}

.policy:hover {
  color: #edd855
}

.col-lg-10l {
  float: left;
  margin-top: 25px;
  border-right: 3px solid transparent;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  flex: 1;
  border-radius: 10px;
  text-align: center;
  width: 50%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
}
.test {
  background: linear-gradient(to bottom, #bbdbf3, #bbdbf3);

}

.social {
  width: 100px;
  display: inline-block;

  box-sizing: border-box;
  padding: 10px
}

.dankeMessage {
  color: #3f51b5;
  margin: auto;
  width: 80%
}

.dankeButton {
  color: #3f51b5
}

.react-checkbox-tree,.warenTree {
  word-wrap: break-word;
  width: 90%;
  margin: auto
}

.rct-title {
  word-wrap: break-word
}

.mark {
  color: #edd855
}


#paypal-button,#stripe-button {
  width: 140px;
  height: 50px;
  padding-left: 20px;
  border-radius: 20px
}

#stripe-button {
 
  background:url('./images/stripe-icon.png') no-repeat center ;
  background-size: contain;
  width: 140px;
  height: 50px;
  padding-left:20px;
  border-radius: 20px;
 
}


small {
  font-size: 0.6em;
}

fieldset,
input,
select {
  border: 1px solid #efefef;
}

.dankeMessage{
  color: #3f51b5;
  margin: auto;
  width: 80%;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.overlay.active {
  display: block;
}

.stripe-container {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  width: 90%; /* Ширина по умолчанию */
  max-width: 325px; /* Максимальная ширина для больших экранов */
}

@media (max-width: 600px) {
  .stripe-container {
    width: 95%; /* Увеличиваем ширину для мобильных устройств */
    padding: 15px; /* Немного уменьшаем отступы */
  }
}


.stripe-container.active {
  display: block;
}

.cookie-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Затемненный фон */
  z-index: 999; /* Помещаем под окно согласия */
}

.cookie-consent {
  position: fixed;
  top: -200px; /* Начальное положение выше экрана */
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px; /* Закругленные углы */
  display: flex;
  flex-direction: column; /* Делаем элементы вертикальными */
  width: 80%;
  max-width: 600px; /* Ограничиваем максимальную ширину */
  z-index: 1000;
  transition: top 0.5s ease; /* Анимация выезда */
}

.cookie-consent.visible {
  top: 20px; /* Позиция, когда окно видимо */
}

.cookie-consent p {
  margin: 0 0 20px 0; /* Увеличиваем отступ снизу */
  text-align: center; /* Центрируем текст */
  font-size: 16px; /* Увеличиваем размер шрифта */
  color: #333; /* Темный цвет текста */
}

.cookie-consent a {
  color: #007BFF; /* Цвет ссылки */
  text-decoration: none;
}

.cookie-consent a:hover {
  text-decoration: underline; /* Подчеркивание при наведении */
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px; /* Расстояние между кнопками */
}

.cookie-consent button {
  padding: 10px 20px; /* Увеличиваем размер кнопок */
  border: none;
  border-radius: 5px; /* Закругленные углы кнопок */
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  font-size: 14px; /* Размер шрифта */
  transition: background-color 0.3s ease;
}

.cookie-consent button:hover {
  background-color: #45a049; /* Изменение цвета при наведении */
}

.cookie-consent button.settings {
  background-color: #007BFF;
}

.cookie-consent button.settings:hover {
  background-color: #0056b3; /* Изменение цвета при наведении */
}






/* index.css */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.ReactModal__Content h2 {
  margin-top: 0;
}

.ReactModal__CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}


button {
  background-color: var(--accent-color);
}


#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 40px;
  margin: 40px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.custom-column {
  word-wrap: break-word;
  text-align: center;
  margin: 20px auto;
  width: 80%;
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.custom-h2 {
  font-size: 1.5em;
  color: #369; /* Голубой цвет */
  margin-bottom: 10px;
  font-weight: 350;
  text-shadow: 2px 8px 70px #000000;
  font-style: normal;
}

.custom-h4 {
  font-size: 2.3em;
  color: #369; /* Голубой цвет */
  margin-bottom: 10px;
  font-weight: 350;
  text-shadow: 2px 8px 70px #000000;
  font-style: normal;
}

.custom-img {
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}

.button-18 {
  align-items: center;
  background-color: #0A66C2;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-18:hover,
.button-18:focus { 
  background-color: #16437E;
  color: #ffffff;
}

.button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
}

.button-18:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}

.completion-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px); /* Full height minus the header height */
  margin-top: 60px; /* Match the height of the header */
  text-align: center;
}


